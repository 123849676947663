export const indianLanguagesList = [
    "Assamese", 
    "Bengali", 
    "Bodo", 
    "Dogri", 
    "English",
    "Gujarati", 
    "Hindi", 
    "Kannada", 
    "Kashmiri", 
    "Konkani", 
    "Maithili", 
    "Malayalam", 
    "Manipuri", 
    "Marathi", 
    "Nepali", 
    "Odia", 
    "Punjabi", 
    "Sanskrit", 
    "Santali", 
    "Sindhi", 
    "Tamil", 
    "Telugu", 
    "Urdu"
];