import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Form,
  Input,
  Select,
  Button,
  Spin,
  notification,
  Radio,
  Row,
  Col,
  DatePicker
} from "antd";
import InputMask from "react-input-mask";
import { Navigate, useNavigate } from "react-router-dom";
import useLocationData from "../../Components/CoustemHooks/countrySateCity";
import { useForm } from "antd/lib/form/Form";
import dayjs from 'dayjs';
import axios from "axios";
import "./Admission.scss";
import TextAreaFloatLabel from "../../Components/Common/FloatLabel/TextArea";
import InputFloatLabel from "../../Components/Common/FloatLabel/Input";
import moment from "moment/moment";
import FloatSelect from "../../Components/Common/FloatLabel/Select";
import Datepicker from "../Common/FloatLabel/Datepicker";
import NotFoundPage from "../NoDataFound/NotFound";
import FloatInputAll from "../Common/FloatLabel/InputAll";
import {
  CloseOutlined,
  CloseSquareOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  
} from "@ant-design/icons";
import SchoolAdmissionForm from "./SchoolAdmissionDetails";
import { indianLanguagesList } from "../../Utils/utils";
const { Option } = Select;

const SubmitButton = ({ form, isTermAndCondition }) => {
  const [submittable, setSubmittable] = React.useState(false);
  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          if (isTermAndCondition) setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values]);
  return (
    <Button
      type="primary"
      className="form-submit"
      htmlType="submit"
      disabled={!submittable}
    >
      Submit
    </Button>
  );
};

const AdmissionDetails = ({ selectedBranch, classNamesArray }) => {
  const [admissionDetailsForm] = Form.useForm();
  const [submittable, setSubmittable] = React.useState(false);

  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [answer, setAnswer] = useState("false");
  const [showTextArea, setShowTextArea] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [siblings, setSiblings] = useState([]);
  const [condition, setCondition] = useState(false);
  const [pointOfContact, setPointOfContact] = useState("");
  const [sourceReason, setSourceReason] = useState(false);
  const [allergies, setAllergies] = useState("false");
  const [schoolUniqueId, setSchoolUniqueId] = useState("");
  const [requestClass, setRequestedClass] = useState(
    sessionStorage.getItem("requestedClass")
  );
  const [classSelected, setClassSelected] = useState("");
  const [branchCode, setBranchCode] = useState(
    sessionStorage.getItem("branchCode")
  );
  const [displayField,setDisplayField]=useState(false)
  const [schoolData, setSchoolData] = useState([]);
  const instituteData = sessionStorage.getItem("userData");
  const [instituteInfo, setInstituteInfo] = useState(
    JSON.parse(instituteData) || []
  );
  const dateFormat = "YYYY-MM-DD";
  const navigate = useNavigate();
  useEffect(() => {
    // admissionDetailsForm.setFieldsValue({
    //   caCountry: "India",
    //   caState: "Telangana",
    // });
  }, []);
  const handleDisplayFields=()=>{
    setDisplayField(!displayField)
  }

  const handleReason = (value) => {
    if (value === "Others") {
      setSourceReason(true);
    } else {
      setSourceReason(false);
    }
  };

  // const addSibling = () => {
  //   setSiblings([
  //     ...siblings,
  //     {
  //       siblingName: "",
  //       siblingRollNumber: "",
  //       siblingClass: "",
  //       siblingBranch: "",
  //     },
  //   ]);
  // };
  const addSibling = () => {
    console.log("add Siblings is working");
    setSiblings([
      ...siblings,
      {
        siblingName: "",
        siblingRollNumber: "",
        siblingClass: "",
        siblingBranch: "",
      },
    ]);
  };
  console.log("siblings", siblings);

  // const nonEmptySiblings = siblings.filter(
  //   (sibling) =>
  //     sibling.siblingName !== "" ||
  //     sibling.siblingRollNumber !== "" ||
  //     sibling.siblingClass !== "" ||
  //     sibling.siblingBranch !== ""
  // );

  // const removeSibling = (index) => {
  //   const updatedSiblings = [...siblings];
  //   updatedSiblings.splice(index, 1);
  //   setSiblings(updatedSiblings);
  // };

  const removeSibling = (index) => {
    console.log("remove siblings working");
    const updatedSiblings = [...siblings];
    updatedSiblings.splice(index, 1);
    setSiblings(updatedSiblings);
  };

  const handleClass = (value) => {
    setClassSelected(value);
  };
  const nationalities = [
    "American",
    "Canadian",
    "British",
    "Australian",
    "French",
    "German",
    "Italian",
    "Spanish",
    "Chinese",
    "Japanese",
    "Indian",
    "Russian",
    "Brazilian",
    "Mexican",
    "South African",
    "Nigerian",
    "Egyptian",
    "Saudi Arabian",
    "Israeli",
    "Iranian",
  ];

  const handleDateChange = () => {};
  // const handleDateChange = (date) => {
  //   setSelectedClass("");
  //   if (date) {
  //     const today = new Date();
  //     const birthDate = new Date(date);
  //     const ageDiff = today.getFullYear() - birthDate.getFullYear();

  //     if (
  //       today.getMonth() < birthDate.getMonth() ||
  //       (today.getMonth() === birthDate.getMonth() &&
  //         today.getDate() < birthDate.getDate())
  //     ) {
  //       ageDiff--;
  //     }

  //     let className = "";

  //     if (ageDiff < 3) {
  //       className = "Pre School";
  //     } else if (ageDiff === 3) {
  //       className = "Nursery";
  //     } else if (ageDiff === 4) {
  //       className = "L.K.G";
  //     } else if (ageDiff === 5) {
  //       className = "U.K.G";
  //     } else {
  //       className = "";
  //     }

  //     setSelectedClass(className);
  //   }
  // };

  const handlePointOfContact = (value) => {
    setPointOfContact(value);
  };

  const handleCheckCondition = (e) => {
    setCondition(e.target.checked);
  };

  const IndianSchoolClasses = [
    "Nursery",
    "L.K.G",
    "U.K.G",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
  ];

  const today = new Date();
  const day = today.getDate();
  const month = today
    .toLocaleString("default", { month: "long" })
    .toUpperCase();
  const year = today.getFullYear();

  const formattedDate = `${day} ${month}, ${year}`;

  const onFinish = (values) => {
    const formattedDateOfBirth = dayjs(values?.dateOfBirth).format("YYYY-MM-DD");
    const siblingDetails = {
      siblingName: values.siblingName,
      siblingRollNumber: values.siblingRollNumber,
      siblingClass: values.siblingClass,
      siblingBranch: values.siblingBranch,
    };
    const payLoad = {
      ...values,
      rollNoStatus: "auto",
      dateOfBirth: formattedDateOfBirth,
      nationality: selectedNationality,
      // aadhaarNumber: values?.aadhaarNumber.replace(/-/g, ""),
      // parentAadhaarNumber: values?.parentAadhaarNumber.replace(/-/g, ""),
      enrolledBranch: instituteInfo?.branchCode,
      healthCondition: answer,
    };

    console.log("pay", payLoad);

    axios
      .post(
        "https://api.edpedia.co/v1.0/admissions/api/enquiry/create",
        payLoad
      )
      .then((response) => {
        // admissionDetailsForm.resetFields();
        notification.success({
          message: `Enquiry created successfully`,
        });
        navigate("/sucess-page", { state: response.data });
      })
      .catch((error) => {
        notification.error({
          message: "An Error Occured",
          description: "Enquiry creating failed, try again later.",
        });
      });
  };

  const religions = [
    "Christianity",
    "Islam",
    "Hinduism",
    "Buddhism",
    "Judaism",
    "Sikhism",
    "Baháʼí Faith",
    "Atheism",
    "Agnosticism",
  ];

  const currentYear = new Date().getFullYear();

  const academicYears = [];
  for (let year = currentYear; year <= currentYear + 1; year++) {
    const academicYear = `${year}-${year + 1}`;
    academicYears.push(academicYear);
  }

  const currentYearRunning = new Date().getFullYear();
  const years = [];

  for (let year = currentYearRunning; year >= currentYearRunning - 19; year--) {
    years.push(year);
  }

  const { countries, states, cities, handleCountryChange, handleStateChange } =
    useLocationData();

  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);

  const onChangeAllergies = (e) => {
    if (e.target.value === "true") {
      setAllergies(e.target.value);
    } else {
      setAllergies(false);
    }
    admissionDetailsForm.setFieldsValue({
      allergies: e.target.value,
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uniqueId = urlParams.get("id");
    setSchoolUniqueId(uniqueId);
    if (uniqueId) {
      setLoading(true);
      axios
        .get(
          `https://api.edpedia.co/v1.0/admissions/api/enquiry-data/${uniqueId}`
        )
        .then((response) => {
          setInstituteInfo(response.data.data);
          setSchoolData(response.data.data);
          sessionStorage.setItem(
            "instituteData",
            JSON.stringify(response.data.data)
          );
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  // useEffect(() => {
  //   admissionDetailsForm.setFieldsValue({
  //     enrolledClass: selectedClass,
  //     instituteName: instituteInfo?.branchName,
  //   });
  // }, [admissionDetailsForm, instituteInfo, selectedClass]);

  useEffect(() => {
    admissionDetailsForm.setFieldsValue({
      healthCondition: answer,
    });
  }, [answer]);

  const handleCountryChange1 = (value) => {
    handleCountryChange(value);
    setIsStateDisabled(!value);
    setIsCityDisabled(true);
    handleStateChange(null);
    admissionDetailsForm.setFieldsValue({ state: undefined });
    admissionDetailsForm.setFieldsValue({ city: undefined });
  };

  const handleStateChange1 = (value) => {
    handleStateChange(value);
    setIsCityDisabled(!value);
    admissionDetailsForm.setFieldsValue({ city: undefined });
  };

  const handleSelectChangeReligion = (selectedOption) => {
    if (selectedOption) {
      setSelectedReligion(selectedOption);
    } else {
      setSelectedReligion(null);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedNationality(selectedOption);
    } else {
      setSelectedNationality(null);
    }
  };
  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
    admissionDetailsForm.setFieldsValue({
      healthCondition: event.target.value,
    });
    setShowTextArea(event.target.value === "true");
  };
  const disabledDate = (current) => {
    return current && current >= moment().subtract(2, "years").endOf("day");
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      const currentAddress = admissionDetailsForm.getFieldValue("caAddress");
      admissionDetailsForm.setFieldsValue({ paAddress: currentAddress });
    } else {
      admissionDetailsForm.setFieldsValue({ paAddress: "" });
    }
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "",
      description: "Please fill all the required fields",
      duration: 5,
      placement: "topRight",
    });
  };



  return (
    <>
      <main>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "40vh" }}>
            <Spin size="large" spinning={loading} />
          </div>
        ) : schoolData.length === 0 ? (
          <NotFoundPage />
        ) : schoolData?.branchName === "Bhrungy International School" ? (
          <SchoolAdmissionForm />
        ) : (
          <div>
            <div className="main_bg">
              <div className="header-container">
                <p className="today-date">{formattedDate}</p>
                <div className="header">
                  <div>
                    <img
                      // src="https://png.pngtree.com/png-clipart/20230407/original/pngtree-education-and-school-logo-design-template-png-image_9035365.png"
                      src={instituteInfo.instLogo}
                      alt="InstituteLogo"
                      className="institute-logo"
                    />
                  </div>
                  <div className="content-container">
                    <h1>{instituteInfo?.branchName || "School Name"}</h1>
                    <h4>{instituteInfo?.address || "School Address"}</h4>
                    {/* <h1>T.I.M.E School</h1>
                  <h4>
                    T I M E School Bandlaguda, Bandlaguda Jagir Village,
                    Rajendranagar Mandal, Survey No. 30, Kismatpur Rd, Near
                    Saraswati Shishu Mandir, Hyderabad, Telangana 500086
                  </h4> */}
                    <h2 className="heading">Online Enquiry Form</h2>
                  </div>
                </div>
              </div>
              <Form
                className="styleCss"
                onFinish={onFinish}
                form={admissionDetailsForm}
                onFinishFailed={onFinishFailed}
                name="admissionDetailsForm"
                layout="vertical"
                onValuesChange={(changedValues, allValues) => {
                  // console.log("All values:", allValues, changedValues);
                }}
                initialValues={{
                  learningSupport: "false",
                  schoolLunch: "true",
                  physicalSupport: "false",
                  healthCondition: "false",
                  allergies: "false",
                  alumnusOfFather: "false",
                  alumnusOfMother: "false",
                  applyEarlier: "false",
                  affiliation: "false",
                }}
                //scrollToFirstError
              >
                <div className="container">
                  <div className="title_container">
                    <h5>Admission Details</h5>
                  </div>
                  <div className="feild_container grid-container">
                    <Form.Item
                      name="academicYear"
                      rules={[{ required: true, message: "" }]}
                      className="select-input-field"
                    >
                      <FloatSelect
                        style={{
                          textAlign: "Left",
                          borderColor: "grey",
                          borderSize: "solid",
                        }}
                        label={<span> Academic Year *</span>}
                      >
                        {academicYears?.map((year) => {
                          return (
                            <Option value={year} key={year}>
                              {year}
                            </Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item>
                    <Form.Item
                      name="admissionType"
                      rules={[{ required: true, message: "" }]}
                      className="select-input-field"
                    >
                      <FloatSelect label={<span> Admission Type (*)</span>}>
                        <Option value="Day Scholar">Day Scholar</Option>
                        <Option value="Day Boarding">Day Boarding</Option>
                        <Option value="Hosteler">Hosteler</Option>
                      </FloatSelect>
                    </Form.Item>
                    {/* <Form.Item
                    name="instituteName"
                    rules={[{ required: true, message: "" }]}
                    className="input-field"
                  >
                    <InputFloatLabel
                      defaultValue={"T.I.M.E School"}
                      // disabled
                      value={"T.I.M.E School"}
                      label={<span>School Name(*) </span>}
                    />
                  </Form.Item> */}
                    <Form.Item
                      name="dateOfBirth"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Datepicker
                        disabledDate={disabledDate}
                        label={<span> Date Of Birth *</span>}
                        format={dateFormat}
                        className="datepicker-field"
                        // onChange={handleDateChange}
                      />
                    </Form.Item>
                    <Form.Item
                      name="board"
                      rules={[{ required: true, message: "" }]}
                      className="select-input-field"
                      // style={{ width: 300 }}
                    >
                      <FloatSelect
                        style={{
                          textAlign: "Left",
                          borderColor: "grey",
                          borderSize: "solid",
                        }}
                        label={<span> Select Board * </span>}
                      >
                        <Option value="State Board">State Board</Option>
                        <Option value="CBSE - Central Board of Secondary Education">
                          CBSE - Central Board of Secondary Education
                        </Option>
                        <Option value="CISCE - Council for the Indian School Certificate Examinations">
                          CISCE - Council for the Indian School Certificate
                          Examinations
                        </Option>
                        <Option value="NIOS - National Institute of Open Schooling">
                          NIOS - National Institute of Open Schooling
                        </Option>
                        <Option value="IB - International Baccalaureate">
                          IB - International Baccalaureate
                        </Option>
                        <Option value="CIE - Cambridge International Examinations">
                          CIE - Cambridge International Examinations
                        </Option>
                      </FloatSelect>
                    </Form.Item>

                    <Form.Item
                      name="enrolledClass"
                      rules={[{ required: true, message: "" }]}
                      className="input-field "
                    >
                      
                      <FloatSelect
                        label="Grade"
                        style={{
                          textAlign: "Left",
                          borderColor: "grey",
                          borderSize: "solid",
                        }}
                        onChange={handleClass}
                      >
                        {IndianSchoolClasses?.map((className) => (
                          <Option key={className} value={className}>
                            {className}
                          </Option>
                        ))}
                      </FloatSelect>
                    </Form.Item>

                    <br/>

                    <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span>First Name *</span>}
                          />
                        </Form.Item>

                        <Form.Item name="middleName" className="input-field ">
                          <InputFloatLabel label={<span>Middle Name </span>} />
                        </Form.Item>

                        
                        <Form.Item
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Last Name *</span>}
                          />
                        </Form.Item>

                        {/* <Form.Item
                      name="academicSession"
                      rules={[{ required: true, message: "" }]}
                      className="select-input-field"
                    >
                      <FloatSelect
                        style={{
                          textAlign: "Left",
                          borderColor: "grey",
                          borderSize: "solid",
                        }}
                        label={<span> Academic Session *</span>}
                      >
                        {academicYears?.map((year) => {
                          return (
                            <Option value={year} key={year}>
                              {year}
                            </Option>
                          );
                        })}
                      </FloatSelect>
                    </Form.Item> */}

                    {/* <Form.Item
                          name="Admission class"
                          rules={[{ required: true, message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            showSearch
                            label={<span>Admission Class *</span>}
                          >
                            <Option value="Nursery">Nursery</Option>
                            <Option value="1st">1st</Option>
                            <Option value="2nd">2nd</Option>
                            <Option value="3rd">3rd</Option>
                            <Option value="4th">4th</Option>
                            <Option value="5th">5th</Option>
                          </FloatSelect>
                        </Form.Item> */}

                        {/* <Form.Item
                          name="Medium"
                          rules={[{ required: true, message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            showSearch
                            label={<span>Medium *</span>}
                          >
                            <Option value="English">English</Option>
                            <Option value="Hindi">Hindi</Option>
                            <Option value="Telugu">Telugu</Option>
                            <Option value="Taamil">Tamil</Option>
                          </FloatSelect>
                        </Form.Item> */}

                        
                        <Form.Item
                          name="SecondLanguage"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            showSearch
                            label={<span>Second Language </span>}
                          >
                            {indianLanguagesList?.map(language => (
                              <Option value={language}>{language}</Option>
                            ))}
                          </FloatSelect>
                        </Form.Item>

                        <Form.Item
                          name="nationality"
                          rules={[{ required: true, message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            showSearch
                            label={<span>Nationality * </span>}
                            onChange={handleSelectChange}
                            value={selectedNationality}
                            style={{ textAlign: "left" }}
                          >
                            {nationalities?.map((nationality, index) => (
                              <option key={index} value={nationality}>
                                {nationality}
                              </option>
                            ))}
                          </FloatSelect>
                        </Form.Item>

                        <Form.Item
                          name="gender"
                          rules={[{ required: true, message: "" }]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            label={<span> Gender * </span> }
                            className="select-input-field"
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Other">Other</Option>
                          </FloatSelect>
                        </Form.Item>

                        {/* <Form.Item
                            name="dateOfBirth"
                            rules={[{ required: true, message: "Please select your date of birth" }]}
                            className="select-input-field"
                            >
                            
                           <DatePicker
                             style={{
                             textAlign: "left",
                             borderColor: "grey",
                             borderStyle: "solid",
                            }}
                          label={<span> DOB </span>}
                          className="select-input-field"
                          />
                       </Form.Item> */}
                        
                       {/* <Form.Item
                          name="SingleParent"
                          rules={[{ required: true, message: "" }]}
                          className="select-input-field"
                        >
                       <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            showSearch
                            label={<span>Single Parent *</span>}
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                           
                          </FloatSelect>
                        </Form.Item> */}
                           
                        <Form.Item
                          name="pocName"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Primary Contact Name * </span>}
                          />
                        </Form.Item>

                        <Form.Item
                          name="pocRelationShip"
                          rules={[{ required: true, message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            showSearch
                            label={<span>Primary Contact Relation * </span>}
                          >
                            <Option value="Father">Father</Option>
                            <Option value="Mother">Mother</Option>
                            <Option value="Local Guardian">Local Guardian</Option>
                            <Option value="Leagle Guardian">Leagle Guardian</Option>
                          </FloatSelect>
                        </Form.Item>

                        <Form.Item
                          name="pocMobileNumber"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Primary Contact Number * </span>}
                            type="number"
                          />
                        </Form.Item>

                        <Form.Item
                          name="SecondaryContactNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Secondary Contact Number  </span>}
                            type="number"
                          />
                        </Form.Item>

                        <Form.Item
                          name="PrimaryContactEmail"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Primary Contact Email  </span>}
                            type="email"
                          />
                        </Form.Item>
                   
                  </div>
                  <div>
                  <Button
                    
                    onClick={handleDisplayFields} 
                    style={{ backgroundColor: '#002355', color: 'white', width:170,padding:4,margin:10, marginRight:1450 }}
                    icon={displayField ? <CloseOutlined/> : <PlusOutlined />}>
                    {displayField ? "Remove Extension" : "Add Extension"}
                    </Button>
                  </div>
                  { !displayField &&
                    <>
                  <div className="container">
                      <div className="title_container">
                        <h5>Affiliation</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="affiliation"
                          rules={[{ required: true, message: "" }]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}>*</span> I/We have
                            interest in, or affiliation / association with any
                            school in the State of Telangana/AP/any other state
                            of India.
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                affiliation: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                     <div className="feild_container">
                      <div style={{ display: "flex" }}>
                        <Checkbox
                          style={{ marginTop: "-45px" }}
                          onChange={handleCheckCondition}
                          value={condition}
                        />
                        <p>
                          I/We hereby declare that the above-mentioned details
                          are true to the best of my/our knowledge. I/We also
                          comprehend that the management reserves the right to
                          offer/decline Admission to my child without assigning
                          any reason. I/We agree that the decision of the
                          Admission Committee will be final and binding. I/We
                          will not hold the school responsible, if I/We do not
                          receive any communication due to incorrect email id or
                          mobile numbers registered in the format as given by
                          the school. I/We give consent to abide by the school
                          norms and cooperate as required in all areas.
                        </p>
                      </div>
                      <div>
                        {/* <SubmitButton
                          form={admissionDetailsForm}
                          isTermAndCondition={condition}
                        /> */}
                        <Button
                          htmlType="submit"
                          className="form-submit"
                          disabled={!condition}
                        >
                          Submit
                        </Button>{" "}
                      </div>
                    </div>
                       </>}
                 
                </div>

                {displayField && (
                  <>
                    {" "}
                    <div className="container">
                      <div className="title_container">
                        <h5>
                          Student Details (Kindly enter as per Birth
                          Certificate)
                        </h5>
                      </div>
                      <div className="feild_container grid-container">
                        {/* <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> First Name (*)</span>}
                          />
                        </Form.Item>
                        <Form.Item name="middleName" className="input-field ">
                          <InputFloatLabel label={<span>Middle Name </span>} />
                        </Form.Item>

                        <Form.Item
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Last Name (*)</span>}
                          />
                        </Form.Item> */}
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Email Id </span>}
                            type="email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="mobileNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Mobile Number </span>}
                            type="number"
                          />
                        </Form.Item>
                        {/* <Form.Item
                          name="gender"
                          rules={[{ required: true, message: "" }]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            label={<span> Gender (*)</span>}
                            className="select-input-field"
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Other">Other</Option>
                          </FloatSelect>
                        </Form.Item> */}

                        <Form.Item
                          name="aadhaarNumber"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          className="input-field "
                        >
                          <InputMask mask="9999-9999-9999" maskChar="_">
                            {() => (
                              <FloatInputAll
                                label={
                                  <span> Enter Aadhaar Card Number</span>
                                }
                              />
                            )}
                          </InputMask>
                        </Form.Item>
                        {/* <Form.Item
                          name="nameAsPerAadhaar"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span> Name as per Aadhaar (*)</span>}
                          />
                        </Form.Item> */}
                        <Form.Item
                          name="motherTongue"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{
                              textAlign: "left",
                              borderColor: "grey",
                              borderWidth: "1px",
                            }}
                            showSearch
                            label={<span>Mother Tongue</span>}
                            className="select-input-field"
                          >
                            <Option value="English">English</Option>
                            <Option value="Hindi">Hindi</Option>
                            <Option value="Bengali">Bengali</Option>
                            <Option value="Telugu">Telugu</Option>
                            <Option value="Marathi">Marathi</Option>
                            <Option value="Tamil">Tamil</Option>
                            <Option value="Urdu">Urdu</Option>
                            <Option value="Gujarati">Gujarati</Option>
                            <Option value="Kannada">Kannada</Option>
                            <Option value="Odia">Odia (Oriya)</Option>
                            <Option value="Punjabi">Punjabi</Option>
                            <Option value="Malayalam">Malayalam</Option>
                            <Option value="Assamese">Assamese</Option>
                            <Option value="Rajasthani">Rajasthani</Option>
                            <Option value="Konkani">Konkani</Option>
                            <Option value="Sindhi">Sindhi</Option>
                            <Option value="Kashmiri">Kashmiri</Option>
                            <Option value="Manipuri">Manipuri</Option>
                            <Option value="Nepali">Nepali</Option>
                            <Option value="Sanskrit">Sanskrit</Option>
                            <Option value="Bodo">Bodo</Option>
                            <Option value="Santhali">Santhali</Option>
                          </FloatSelect>
                        </Form.Item>

                        {/* <Form.Item
                          name="nationality"
                          rules={[{ required: true, message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            showSearch
                            label={<span>Nationality (*)</span>}
                            onChange={handleSelectChange}
                            value={selectedNationality}
                            style={{ textAlign: "left" }}
                          >
                            {nationalities?.map((nationality, index) => (
                              <option key={index} value={nationality}>
                                {nationality}
                              </option>
                            ))}
                          </FloatSelect>
                        </Form.Item> */}

                        <Form.Item
                          name="religion"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{ textAlign: "left" }}
                            onChange={handleSelectChangeReligion}
                            value={selectedReligion}
                            showSearch
                            label={<span>Religion </span>}
                          >
                            {religions?.map((religion, index) => (
                              <option key={index} value={religion}>
                                {religion}
                              </option>
                            ))}
                          </FloatSelect>
                        </Form.Item>

                        <Form.Item
                          name="passportNumber"
                          rules={[
                            {
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span>Passport Number</span>}
                            //type="number"
                            type="alphanumeric"
                          />
                        </Form.Item>

                        <Form.Item
                          name="caste"
                          rules={[{ 
                            // required: true, 
                            message: "" }]}
                          className="input-field "
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            showSearch
                            label={<span>Select Caste </span>}
                          >
                            <Option value="OC">OC</Option>
                            <Option value="OBC">OBC</Option>
                            <Option value="BC">BC</Option>
                            <Option value="BC-A">BC-A</Option>
                            <Option value="BC-B">BC-B</Option>
                            <Option value="BC-C">BC-C</Option>
                            <Option value="BC-D">BC-D</Option>
                            <Option value="BC-E">BC-E</Option>
                            <Option value="SC">SC</Option>
                            <Option value="ST">ST</Option>
                            <Option value="GENARAL">GENARAL</Option>
                          </FloatSelect>
                        </Form.Item>

                        <Form.Item
                          name="bloodGroup"
                          rules={[{
                            //  required: true, 
                             message: "" }]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                            label={<span>Blood Group </span>}
                            showSearch
                          >
                            <Option value="A+">A+</Option>
                            <Option value="A-">A-</Option>
                            <Option value="B+">B+</Option>
                            <Option value="B-">B-</Option>
                            <Option value="O+">O+</Option>
                            <Option value="O-">O-</Option>
                            <Option value="AB+">AB+</Option>
                            <Option value="AB-">AB-</Option>
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="applyEarlier"
                          rules={[{
                            //  required: true,
                              message: "" }]}
                          // label="Health Condition"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}></span> Applied
                            Earlier?
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                applyEarlier: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>
                          Previous School Details (Kindly type NA if not
                          applicable)
                        </h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="attendedYear"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            label={<span> Attended Year </span>}
                            style={{ textAlign: "Left" }}
                          >
                            {years.map((year) => (
                              <Option key={year} value={year.toString()}>
                                {year}
                              </Option>
                            ))}
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="attendedSchoolName"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <FloatInputAll label={<span>School Name </span>} />
                        </Form.Item>
                        <Form.Item
                          name="attendedClass"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <FloatInputAll label={<span>Class </span>} />
                        </Form.Item>
                        {/* <Form.Item
                    name="attendedClass"
                    rules={[{ required: true, message: "" }]}
                    className="select-input-field"
                  >
                    <FloatSelect
                      label={<span>Class (*)</span>}
                      style={{
                        textAlign: "Left",
                        borderColor: "grey",
                        borderSize: "solid",
                      }}
                    >
                      {IndianSchoolClasses.slice(
                        0,
                        IndianSchoolClasses.indexOf(instituteInfo?.className)
                      ).map((className) => (
                        <Option key={className} value={className}>
                          {className}
                        </Option>
                      ))}
                    </FloatSelect>
                  </Form.Item> */}
                        {/* <Form.Item
                          name="attendedBoard"
                          rules={[{ required: true, message: "" }]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            label={<span>Attended Board (*)</span>}
                            style={{
                              textAlign: "Left",
                              borderColor: "grey",
                              borderSize: "solid",
                            }}
                          >
                            <Option value="State Board">State Board</Option>
                            <Option value="CBSE - Central Board of Secondary Education">
                              CBSE - Central Board of Secondary Education
                            </Option>
                            <Option value="CISCE - Council for the Indian School Certificate Examinations">
                              CISCE - Council for the Indian School Certificate
                              Examinations
                            </Option>
                            <Option value="NIOS - National Institute of Open Schooling">
                              NIOS - National Institute of Open Schooling
                            </Option>
                            <Option value="IB - International Baccalaureate">
                              IB - International Baccalaureate
                            </Option>
                            <Option value="CIE - Cambridge International Examinations">
                              CIE - Cambridge International Examinations
                            </Option>
                          </FloatSelect>
                        </Form.Item> */}
                        <Form.Item
                          name="attendedBoard"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field "
                        >
                          <FloatInputAll label={<span>Attended Board </span>} />
                        </Form.Item>
                        <Form.Item
                          name="reasonForLeaving"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TextAreaFloatLabel
                            style={{ padding: "5px" }}
                            label={<span>Reason For Leaving </span>}
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            className="textarea-field"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>
                          Identification Details (Kindly type NA if none )
                        </h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="identificationMark1"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TextAreaFloatLabel
                            style={{ padding: "5px" }}
                            label={<span>Identification Mark 1 </span>}
                            className="textarea-field"
                            autoSize={{ minRows: 2, maxRows: 3 }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="identificationMark2"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TextAreaFloatLabel
                            label={<span>Identification Mark 2 </span>}
                            className="textarea-field"
                            autoSize={{ minRows: 2, maxRows: 3 }}
                            style={{ padding: "5px" }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>Accommodations & Medical Information</h5>
                      </div>
                      <div className="feild_container">
                        {/* <Form.Item
                    name="physicalSupport"
                    rules={[{ required: true, message: "" }]}
                    className="select-input-field"
                  >
                    <FloatSelect
                      className="select-input-field"
                      label={<span>Physical Support (*)</span>}
                      style={{
                        textAlign: "Left",
                        borderColor: "grey",
                        borderSize: "solid",
                      }}
                    >
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </FloatSelect>
                  </Form.Item> */}

                        <Form.Item
                          name="learningSupport"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}> </span>Does your
                            child need any learning support?
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                learningSupport: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name="physicalSupport"
                          rules={[{
                            //  required: true,
                              message: "" }]}
                          // label="Health Condition"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}></span> Does your
                            child need any physical support?
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                physicalSupport: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            name="allergies"
                            rules={[{ 
                              // required: true,
                               message: "" }]}
                            // label="Health Condition"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label style={{ marginRight: "16px" }}>
                              <span style={{ color: "red" }}> </span>Does your
                              child have any allergies?
                            </label>
                            <Radio.Group
                              onChange={onChangeAllergies}
                              defaultValue={"false"}
                            >
                              <Radio value="true">Yes</Radio>
                              <Radio value="false">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                          {allergies === "true" && (
                            <Form.Item
                              name="allergiesOtherInfo"
                              rules={[{
                                //  required: true,
                                  message: "" }]}
                              className="input-field "
                            >
                              <FloatInputAll
                                label={
                                  <span>
                                    {" "}
                                    Please Provide Other Information 
                                  </span>
                                }
                                name="allergiesOtherInfo"
                              />
                            </Form.Item>
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            name="healthCondition"
                            rules={[{
                              //  required: true,
                                message: "" }]}
                            // label="Health Condition"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label style={{ marginRight: "16px" }}>
                              <span style={{ color: "red" }}> </span>Does your
                              child have any other health conditions?
                            </label>
                            <Radio.Group
                              onChange={handleAnswerChange}
                              defaultValue={"false"}
                            >
                              <Radio value="true">Yes</Radio>
                              <Radio value="false">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                          {/* {showTextArea && (
                        <Form.Item label="Please Provide Your Health Condition Details">
                          <Input.TextArea rows={2} />
                        </Form.Item>
                      )} */}
                          {showTextArea && (
                            <Form.Item
                              name="healthConditionInfo"
                              rules={[{
                                //  required: true,
                                  message: "" }]}
                              className="input-field "
                            >
                              <FloatInputAll
                                label={
                                  <span>
                                    {" "}
                                    Please Provide Other Information
                                  </span>
                                }
                                name="healthConditionInfo"
                              />
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>Family Details</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="fatherName"
                          rules={[{
                            //  required: true, 
                             message: "" }]}
                        >
                          <InputFloatLabel
                            label={<span>Father's Name </span>}
                          />
                        </Form.Item>

                        <Form.Item
                          name="fatherOccupation"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          className="input-field"
                        >
                          <FloatSelect
                            style={{ textAlign: "Left" }}
                            showSearch
                            label={<span>Father's Occupation </span>}
                          >
                            <Option value="Teacher">Teacher</Option>
                            <Option value="Engineer">Engineer</Option>
                            <Option value="Doctor">Doctor</Option>
                            <Option value="Accountant">Accountant</Option>
                            <Option value="Attorney/Lawyer">
                              Attorney/Lawyer
                            </Option>
                            <Option value="Business Owner">
                              Business Owner
                            </Option>
                            <Option value="Sales/Marketing Professional">
                              Sales/Marketing Professional
                            </Option>
                            <Option value="IT/Computer Professional">
                              IT/Computer Professional
                            </Option>
                            <Option value="Architect">Architect</Option>
                            <Option value="Scientist">Scientist</Option>
                            <Option value="Nurse">Nurse</Option>
                            <Option value="Financial Analyst">
                              Financial Analyst
                            </Option>
                            <Option value="Artist">Artist</Option>
                            <Option value="Writer">Writer</Option>
                            <Option value="Social Worker">Social Worker</Option>
                            <Option value="Police Officer">
                              Police Officer
                            </Option>
                            <Option value="Firefighter">Firefighter</Option>
                            <Option value="Construction Worker">
                              Construction Worker
                            </Option>
                            <Option value="Mechanic">Mechanic</Option>
                            <Option value="Electrician">Electrician</Option>
                            <Option value="Others">Others</Option>
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="fatherOfficeAddress"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                        >
                          <FloatInputAll
                            label={<span>Father's Office Address</span>}
                          />
                        </Form.Item>
                        <Form.Item
                          name="fatherQualification"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <FloatSelect
                            label={<span>Father's Qualification </span>}
                            style={{ textAlign: "Left" }}
                          >
                            <Option value="HighSchoolDiplomaOrEquivalent">
                              High School Diploma or Equivalent
                            </Option>
                            <Option value="AssociateDegree">
                              Associate Degree
                            </Option>
                            <Option value="BachelorsDegree">
                              Bachelor's Degree
                            </Option>
                            <Option value="MastersDegree">
                              Master's Degree
                            </Option>
                            <Option value="Doctorate">
                              Doctorate (Ph.D. or equivalent)
                            </Option>
                            <Option value="Professional Degree">
                              Professional Degree (e.g., MD, JD, MBA)
                            </Option>
                            <Option value="VocationalOrTechnicalCertificate">
                              Vocational or Technical Certificate
                            </Option>
                            <Option value="HighSchoolIncomplete">
                              High School Incomplete
                            </Option>
                            <Option value="SomeCollegeNoDegree">
                              Some College, No Degree
                            </Option>
                            <Option value="Others">Others</Option>
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="fatherMobileNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Father's Mobile Number </span>}
                            type="number"
                          />
                        </Form.Item>
                        <Form.Item
                          name="parentWhatsappNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field "
                        >
                          <InputFloatLabel
                            label={<span>Parent's Whatsapp Number </span>}
                            type="number"
                          />
                        </Form.Item>
                        {/* <Form.Item
                name="parentAadhaarNumber"
                label="parent AadhaarNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    min: 12,
                    message: "Please enter valid adhar number",
                  },
                  {
                    max: 12,
                    message: "Cannot exceed more than 12",
                  },
                ]}
                className="input-field"
              >
                <Input placeholder="Parent's Aadhaar Number" />
              </Form.Item> */}
                        <Form.Item
                          name="fatherEmailId"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Father's Email Id </span>}
                            type="email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="parentAadhaarNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputMask mask="9999-9999-9999" maskChar="_">
                            {() => (
                              <FloatInputAll
                                label={<span>Parent's Aadhaar Number </span>}
                              />
                            )}
                          </InputMask>
                        </Form.Item>
                        <Form.Item
                          name="alumnusOfFather"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}></span> Alumnus of
                            School
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                alumnusOfFather: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="motherName"
                          rules={[{
                            //  required: true,
                             message: "" }]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Mother's Name</span>}
                          />
                        </Form.Item>
                        <Form.Item
                          name="motherOccupation"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                        >
                          <FloatSelect
                            label={<span>Mother's Occupation</span>}
                            style={{ textAlign: "Left" }}
                            showSearch
                          >
                            <Option value="Homemaker">Homemaker</Option>
                            <Option value="Teacher">Teacher</Option>
                            <Option value="Nurse">Nurse</Option>
                            <Option value="Doctor">Doctor</Option>
                            <Option value="Engineer">Engineer</Option>
                            <Option value="Accountant">Accountant</Option>
                            <Option value="Attorney/Lawyer">
                              Attorney/Lawyer
                            </Option>
                            <Option value="Business Owner">
                              Business Owner
                            </Option>
                            <Option value="Sales/Marketing Professional">
                              Sales/Marketing Professional
                            </Option>
                            <Option value="IT/Computer Professional">
                              IT/Computer Professional
                            </Option>
                            <Option value="Artist">Artist</Option>
                            <Option value="Writer">Writer</Option>
                            <Option value="Social Worker">Social Worker</Option>
                            <Option value="Administrative Assistant">
                              Administrative Assistant
                            </Option>
                            <Option value="Retail Worker">Retail Worker</Option>
                            <Option value="Scientist">Scientist</Option>
                            <Option value="Librarian">Librarian</Option>
                            <Option value="Chef/Cook">Chef/Cook</Option>
                            <Option value="Financial Analyst">
                              Financial Analyst
                            </Option>
                            <Option value="Others">Others</Option>
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="motherOfficeAddress"
                          rules={[
                            {
                              //required: true,
                              message: "",
                            },
                          ]}
                        >
                          <FloatInputAll
                            label={<span>Mother's Office Address </span>}
                          />
                        </Form.Item>
                        <Form.Item
                          name="motherQualification"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <FloatSelect
                            label={<span>Mother's Qualification </span>}
                            style={{ textAlign: "Left" }}
                          >
                            <Option value="HighSchoolDiplomaOrEquivalent">
                              High School Diploma or Equivalent
                            </Option>
                            <Option value="AssociateDegree">
                              Associate Degree
                            </Option>
                            <Option value="BachelorsDegree">
                              Bachelor's Degree
                            </Option>
                            <Option value="MastersDegree">
                              Master's Degree
                            </Option>
                            <Option value="Doctorate">
                              Doctorate (Ph.D. or equivalent)
                            </Option>
                            <Option value="Professional Degree">
                              Professional Degree (e.g., MD, JD, MBA)
                            </Option>
                            <Option value="VocationalOrTechnicalCertificate">
                              Vocational or Technical Certificate
                            </Option>
                            <Option value="HighSchoolIncomplete">
                              High School Incomplete
                            </Option>
                            <Option value="SomeCollegeNoDegree">
                              Some College, No Degree
                            </Option>
                            <Option value="Others">Others</Option>
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="motherMobileNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            type="number"
                            label={<span>Mother's Mobile Number </span>}
                          />
                        </Form.Item>
                        <Form.Item
                          name="motherEmailId"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Mother's Email Id</span>}
                            type="email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="alumnusOfMother"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}></span> Alumnus of
                            School
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                alumnusOfMother: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>
                          Point of Contact Details (These details will be used
                          in the future for any communication from school via
                          SMS / EMAIL)
                        </h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="pocRelationShip"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          className="input-field"
                        >
                          <FloatSelect
                            style={{ textAlign: "Left" }}
                            label={<span>Point Of contact </span>}
                            onChange={handlePointOfContact}
                          >
                            <Option value="Father">Father</Option>
                            <Option value="Mother">Mother</Option>
                            <Option value="Guardian">Guardian</Option>
                          </FloatSelect>
                        </Form.Item>
                        {pointOfContact === "Guardian" && (
                          <>
                            <Form.Item
                              name="pocName"
                              rules={[{ 
                                // required: true,
                                 message: "" }]}
                              className="input-field"
                            >
                              <InputFloatLabel
                                label={<span>Point Of Contact Name </span>}
                              />
                            </Form.Item>
                            <Form.Item
                              name="pocMobileNumber"
                              rules={[
                                {
                                  // required: true,
                                  message: "",
                                },
                                {
                                  min: 10,
                                  message: "Please enter valid phone number",
                                },
                                {
                                  max: 10,
                                  message: "Cannot exceed more than 10",
                                },
                              ]}
                              className="input-field"
                            >
                              <InputFloatLabel
                                label={<span>Mobile Number </span>}
                                type="number"
                              />
                            </Form.Item>
                            <Form.Item
                              name="pocEmailId"
                              rules={[
                                {
                                  // required: true,
                                  message: "",
                                },
                              ]}
                              className="input-field"
                            >
                              <InputFloatLabel
                                label={<span>Email </span>}
                                type="email"
                              />
                            </Form.Item>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>
                          Sibling Details (If Studying in the same school)
                        </h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.List name="siblings">
                          {(fields, { add, remove }) => (
                            <div className="siblings-main-container">
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add();
                                    addSibling();
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  Add Sibling
                                </Button>
                              </Form.Item>
                              {fields.map(
                                ({ key, name, ...restField }, index) => (
                                  <div className="sibling-details" key={key}>
                                    <Form.Item
                                      name={[name, "siblingName"]}
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: "Please input sibling name",
                                      //   },
                                      // ]}
                                      className="sibling-input-field"
                                    >
                                      <Input placeholder="Sibling Name" />
                                    </Form.Item>
                                    <Form.Item
                                      name={[name, "siblingRollNumber"]}
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: "Please input roll number",
                                      //   },
                                      // ]}
                                      className="sibling-input-field"
                                    >
                                      <Input placeholder="Roll Number" />
                                    </Form.Item>
                                    <Form.Item
                                      name={[name, "siblingClass"]}
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: "Please input class",
                                      //   },
                                      // ]}
                                      className="sibling-input-field"
                                    >
                                      <Input placeholder="Class" />
                                    </Form.Item>
                                    <Form.Item
                                      name={[name, "siblingBranch"]}
                                      // rules={[
                                      //   {
                                      //     required: true,
                                      //     message: "Please input branch",
                                      //   },
                                      // ]}
                                      className="sibling-input-field"
                                    >
                                      <Input placeholder="Branch" />
                                    </Form.Item>
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => {
                                          remove(name);
                                          removeSibling(index);
                                        }}
                                        icon={<CloseSquareOutlined />}
                                      >
                                        Remove
                                      </Button>
                                    </Form.Item>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </Form.List>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5> Current Address Details</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="caCountry"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <FloatSelect
                            label={<span>Country </span>}
                            onChange={handleCountryChange1}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                          >
                            {countries.map((country) => (
                              <Option
                                key={country.isoCode}
                                value={country.isoCode}
                              >
                                {country.name}
                              </Option>
                            ))}
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="caState"
                          rules={[{
                            //  required: true,
                              message: "" }]}
                          className="input-field"
                        >
                          <FloatSelect
                            label={<span>State</span>}
                            onChange={handleStateChange1}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            disabled={isStateDisabled}
                          >
                            {states.map((state) => (
                              <Option key={state.isoCode} value={state.isoCode}>
                                {state.name}
                              </Option>
                            ))}
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="caCity"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <FloatSelect
                            label={<span>City</span>}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            disabled={isCityDisabled}
                          >
                            {cities.map((city) => (
                              <Option key={city.cityId} value={city.name}>
                                {city.name}
                              </Option>
                            ))}
                          </FloatSelect>
                        </Form.Item>
                        <Form.Item
                          name="caZipcode"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 6,
                              message: "Please enter valid pincode",
                            },
                            {
                              max: 6,
                              message: "Pincode cannot exceed more than 6",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            label={<span>Pin Code </span>}
                            type="number"
                          />
                        </Form.Item>
                        <Form.Item
                          name="caAddress"
                          // label="Current Address"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TextAreaFloatLabel
                            style={{ padding: "5px" }}
                            className="textarea-field "
                            label={<span>Current Address </span>}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5> Parmanent Address Details</h5>
                        <Form.Item
                          name="sameAsCurrent"
                          valuePropName="checked"
                          style={{ marginLeft: 300, marginTop: -32 }}
                        >
                          <Checkbox
                            style={{ color: "white" }}
                            onChange={handleCheckboxChange}
                          >
                            Same as current address
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="paAddress"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TextAreaFloatLabel
                            className="textarea-field "
                            label={<span>Permanent Address</span>}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>Emergency Contact Details (Other than parents)</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="ecName"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel label={<span>Name</span>} />
                        </Form.Item>
                        <Form.Item
                          name="ecMobileNumber"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 10,
                              message: "Cannot exceed more than 10",
                            },
                          ]}
                          className="input-field"
                        >
                          <InputFloatLabel
                            type="number"
                            label={<span>Mobile Number </span>}
                          />
                        </Form.Item>
                        <Form.Item
                          name="ecRelationship"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            style={{ textAlign: "Left" }}
                            label={<span>Relationship</span>}
                          >
                            <Option value="Brother">Brother</Option>
                            <Option value="Sister">Sister</Option>
                            <Option value="Friend">Friend</Option>
                            <Option value="Cousin">Cousin</Option>
                            <Option value="Uncle">Uncle</Option>
                            <Option value="GrandParents">Grand Parents</Option>
                          </FloatSelect>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>School Lunch</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="schoolLunch"
                          rules={[{ 
                            // required: true,
                             message: "" }]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}></span> Would you
                            like to opt for school lunch?
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                schoolLunch: e.target.value,
                              })
                            }
                            defaultValue={"true"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>Where Did You Hear About Us ?</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="sourceOfInfo"
                          rules={[
                            {
                              // required: true,
                              message: "",
                            },
                          ]}
                          className="select-input-field"
                        >
                          <FloatSelect
                            label={<span>Source Of Info </span>}
                            style={{ textAlign: "Left" }}
                            onChange={handleReason}
                          >
                            <Option value="News">News</Option>
                            <Option value="Website">Website</Option>
                            <Option value="LinkedIn">Linked In</Option>
                            <Option value="Instgram">Instgram</Option>
                            <Option value="Google">Google</Option>
                            <Option value="SocialMedia">Social Media</Option>
                            <Option value="Referal">Referal</Option>
                            <Option value="Others">Others</Option>
                          </FloatSelect>
                        </Form.Item>
                        {sourceReason && (
                          <Form.Item
                            name="sourceOtherInfo"
                            rules={[{ 
                              //  required: true,
                                message: "" }]}
                            className="input-field "
                          >
                            <InputFloatLabel
                              label={
                                <span>
                                  {" "}
                                  Please Provide Other Information 
                                </span>
                              }
                              name="sourceOtherInfo"
                            />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    <div className="container">
                      <div className="title_container">
                        <h5>Affiliation</h5>
                      </div>
                      <div className="feild_container grid-container">
                        <Form.Item
                          name="affiliation"
                          rules={[{
                            //  required: true, 
                             message: "" }]}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label style={{ marginRight: "16px" }}>
                            <span style={{ color: "red" }}></span> I/We have
                            interest in, or affiliation / association with any
                            school in the State of Telangana/AP/any other state
                            of India.
                          </label>
                          <Radio.Group
                            onChange={(e) =>
                              admissionDetailsForm.setFieldsValue({
                                affiliation: e.target.value,
                              })
                            }
                            defaultValue={"false"}
                          >
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="feild_container">
                      <div style={{ display: "flex" }}>
                        <Checkbox
                          style={{ marginTop: "-45px" }}
                          onChange={handleCheckCondition}
                          value={condition}
                        />
                        <p>
                          I/We hereby declare that the above-mentioned details
                          are true to the best of my/our knowledge. I/We also
                          comprehend that the management reserves the right to
                          offer/decline Admission to my child without assigning
                          any reason. I/We agree that the decision of the
                          Admission Committee will be final and binding. I/We
                          will not hold the school responsible, if I/We do not
                          receive any communication due to incorrect email id or
                          mobile numbers registered in the format as given by
                          the school. I/We give consent to abide by the school
                          norms and cooperate as required in all areas.
                        </p>
                      </div>
                      <div>
                        {/* <SubmitButton
                          form={admissionDetailsForm}
                          isTermAndCondition={condition}
                        /> */}
                        <Button
                          htmlType="submit"
                          className="form-submit"
                          disabled={!condition}
                        >
                          Submit
                        </Button>{" "}
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        )}
      </main>
    </>
  );
};
export default AdmissionDetails;
